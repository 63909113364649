import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactForm from "components/ContactForm"

import {
  Content,
  Baner,
  Partners,
  // Financing
} from "page_components/benefits"

const Support = ({ location }) => {
  const title = "Benefits Package"

  return (
    <Layout
      location={location}
      seo={{
        title: "Pakiet korzyści dla Klientów",
        description:
          "Kup mieszkanie i odbierz pakiet rabatowy na wykończenie i wyposażenie mieszkania u naszych partnerów. Dołącz do programy Złota Karta Trust Investment!",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <Baner />
      <Partners />
      {/* <Financing /> */}
      <ContactForm />
    </Layout>
  )
}

export default Support
